import React, {Fragment} from "react";
import {AppBar, IconButton, Toolbar, Typography, withStyles} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import {Route} from "react-router-dom";
import {LoginConsumer} from "../global";

const styles = () => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
});

const MainBar = (props) => {
  const {classes} = props;

  return (
    <AppBar style={{position: "fixed", top: 0}}>
      <Toolbar>
        <IconButton color={'inherit'}
                    className={classes.menuButton}
                    onClick={props.toggleMenu}>
          <MenuIcon/>
        </IconButton>
        <Typography variant={'h6'} color={'inherit'} className={classes.grow} noWrap>
          <Route exact path={'/'} render={() => (
            <Fragment>
              Main Page
            </Fragment>
          )}/>
          <Route path={'/code'} render={() => (
            <Fragment>
              Projects
            </Fragment>
          )}/>
          <Route path={'/migrate'} render={() => (
            <Fragment>
              Migration
            </Fragment>
          )}/>
        </Typography>

        <LoginConsumer>
          {({user, delLoginUser}) => <Fragment>
            <Typography color={"inherit"}>
              {user && "Hello " + user}
            </Typography>
            {user &&
            <IconButton
              variant={"fab"}
              color={"inherit"}
              onClick={delLoginUser}
            >
              <PowerSettingsNewIcon/>
            </IconButton>
            }
          </Fragment>}
        </LoginConsumer>
      </Toolbar>
    </AppBar>
  )
};

export default withStyles(styles)(MainBar);