import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {withCookies} from 'react-cookie';
import {LoginConsumer} from "../global";
import TextField from "@material-ui/core/es/TextField/TextField";

class Login extends Component {

  state = {
    user: "",
  };

  render() {
    return (
      <LoginConsumer>
        {({user, setLoginUser}) =>
          <Dialog open={!(user)}>
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
              <TextField
                label={"Who are you?"}
                onChange={(e) => this.setState({user: e.target.value})}
                onKeyDown={event => {
                  if (event.keyCode === 13) {
                    setLoginUser(this.state.user)
                  }
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color={"primary"}
                onClick={() => setLoginUser(this.state.user)}
              >
                Go!
              </Button>
            </DialogActions>
          </Dialog>
        }
      </LoginConsumer>
    )
  }
}

export default withCookies(Login);