import {Drawer, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {Link} from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import React from "react";

const MainNav = (props) => {
  return (
    <Drawer open={props.menuOpen} onClose={props.toggleMenu}>
      <div tabIndex={0} role={'button'}>
        <List>
          <ListItem button component={Link} to={'/'} onClick={props.toggleMenu}>
            <ListItemIcon>
              <HomeIcon/>
            </ListItemIcon>
            <ListItemText primary={'Home'}/>
          </ListItem>
          <ListItem button component={Link} to={'/code'} onClick={props.toggleMenu}>
            <ListItemIcon>
              <HomeIcon/>
            </ListItemIcon>
            <ListItemText primary={'Projects'}/>
          </ListItem>
        </List>
      </div>
    </Drawer>
  )
};

export default MainNav;