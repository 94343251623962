import React, {Component, Fragment} from "react";
import {IconButton, LinearProgress, Paper, TextField} from "@material-ui/core";
import RouterDetailComponent from "./routerDetail";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from '@material-ui/icons/Refresh';
import LoadingButton from "../loadingButton";
import WaitingJobsAlert from "./waitingJobsAlert";


class MigrationComponent extends Component {

  state = {
    routerText: "",
    routersList: [],
    routers: [
      {
        productclass: "-",
        serialnumber: "Placeholder",
        manufacturer: "-",
        devicefirmware: "-",
        externalipaddress: "-",
        serverurl: "content",
        connectiontype: "1",
      }
    ],
    expanded: null,
    routersLoading: false,
    migrateLoading: false,
    migrateSuccess: false,
    jobsDialogOpen: false,
    jobsDialogSerial: "",
    jobsDialogJobs: [],

  };

  _handleRouterSummaryChange = panel => (e, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    })
  };

  _handleTextFieldChange = (e) => {
    if (e.target.id === "routerInput") {
      this.setState({
        routersList: e.target.value.split("\n").filter(router => router.trim() !== "").map(router => router.trim())
      }, this.getRouters);
    }
  };

  getWaitingJobs = serialnumber => {
    console.log("Getting jobs for:", serialnumber);
    fetch("https://mephala-config.juniperbridge.com/api/device/" + serialnumber + "/jobs")
      .then(res => res.json())
      .then(data => {
        const jobsData = (data.results)?data.results:[];
        console.log("Jobs data:", data, jobsData);
        this.setState({
          jobsDialogOpen: true,
          jobsDialogSerial: serialnumber,
          jobsDialogJobs: jobsData,
        });
      })
      .catch(err => {
        console.log("Failed to fetch waiting jobs:", err)
      });
  };

  closeWaitingJobs = () => {
    this.setState({
      jobsDialogOpen: false,
    })
  };

  getRouters() {
    let routersList = this.state.routersList;
    this.setState({
      routersLoading: true,
    });
    Promise.all(routersList.map((router) => {
      return fetch("https://mephala-config.juniperbridge.com/api/device/" + router)
    }))
      .then((resArray) => Promise.all(resArray.map((res) => {
        return res.json();
      })))
      .then(dataArray => {
        const routersArray = dataArray.map((routerRes) => {
          return (routerRes.result) ? routerRes.result : {};
        });
        console.log(routersArray);
        this.setState({
          routers: routersArray,
          routersLoading: false
        });
      })
      .catch(err => {
        console.log("Fail when fetching:", err);
        this.setState({
          routersLoading: false
        })
      });
  }

  migrateAll = () => {
    this.setState({
      migrateLoading: true
    });
    Promise.all(this.state.routersList.map(router => {
      const apiURL = "https://mephala-config.juniperbridge.com/api/device/" + router + "/set";
      return fetch(apiURL, {
        method: 'post',
        // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: new URLSearchParams({
          "jobname": "serverurl",
          "jobvalue": "http://verastar.broadbandsetup.uk/bootstrap"
        })
      })
    }))
      .then(resArray => Promise.all(resArray.map((res) => {
        return res.json()
      })))
      .finally(() => {
        this.setState({
          migrateLoading: false,
          migrateSuccess: true,
        })
      })
  };

  render() {
    const {expanded} = this.state;
    return (
      <Fragment>
        <WaitingJobsAlert
          open={this.state.jobsDialogOpen}
          waitingJobs={this.state.jobsDialogJobs}
          serialnumber={this.state.jobsDialogSerial}
          onClose={this.closeWaitingJobs}
        />
        {/*<Dialog*/}
        {/*open={this.state.open}*/}
        {/*onClose={this.handleClose}*/}
        {/*aria-labelledby="alert-dialog-title"*/}
        {/*aria-describedby="alert-dialog-description"*/}
        {/*>*/}
        {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
        {/*<DialogContent>*/}
        {/*<DialogContentText id="alert-dialog-description">*/}
        {/*Let Google help apps determine location. This means sending anonymous location data to*/}
        {/*Google, even when no apps are running.*/}
        {/*</DialogContentText>*/}
        {/*</DialogContent>*/}
        {/*<DialogActions>*/}
        {/*<Button onClick={this.handleClose} color="primary">*/}
        {/*Disagree*/}
        {/*</Button>*/}
        {/*<Button onClick={this.handleClose} color="primary" autoFocus>*/}
        {/*Agree*/}
        {/*</Button>*/}
        {/*</DialogActions>*/}
        {/*</Dialog>*/}
        <Grid container>
          <Grid container item xs={3} spacing={8} direction={"column"}>
            <Grid item>
              <TextField
                id={'routerInput'}
                label={'Insert Serial Numbers'}
                multiline
                variant={'outlined'}
                onChange={this._handleTextFieldChange}
              />
              <IconButton onClick={() => {
                this.getRouters()
              }}>
                <RefreshIcon/>
              </IconButton>
            </Grid>

            <Grid item>
              <LoadingButton
                loading={this.state.migrateLoading}
                success={this.state.migrateSuccess}
                text={"Migrate All"}
                onClick={this.migrateAll}
              />
            </Grid>
          </Grid>

          <Grid item xs={9}>
            {this.state.routersLoading && <LinearProgress variant={"query"}/>}
            <Paper style={{
              // maxHeight: 400,
              // overflow: 'auto',
            }}>
              {this.state.routers.map((router, index) => {
                return (
                  <RouterDetailComponent
                    key={index}
                    router={router}
                    expanded={expanded === 'router-' + index}
                    handleChange={this._handleRouterSummaryChange('router-' + index)}
                    handleGetJobs={this.getWaitingJobs}
                  />
                )
              })}
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default MigrationComponent