import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import {Button, Card, CardContent, Divider, TextField, Typography, withStyles} from "@material-ui/core";
import {APIUrl, handleError, LoginConsumer, textToComponents} from "../../global";

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

class CodeSubmission extends Component {
  state = {
    content: "",
    editing: false
  };

  handleChange = (change) => {
    this.setState({
      content: change.target.value
    });
  };

  handleSubmit = (data) => {
    this.setState({
      editing: false,
    });
    this.props.submit(data);
  };

  componentDidMount() {
    fetch(APIUrl + this.props.path)
      .then(res => res.json())
      .then(data => {
        this.setState({
          content: data
        })
      })
      .catch(err => {
        handleError("Get Submission data", err)
        this.setState({
          editing: true
        })
      })
  }

  render() {
    return (
      <Fragment>
        <Grid container spacing={8}>
          {this.state.editing &&
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography color={"textSecondary"} gutterBottom>
                  Your submission
                </Typography>
                <TextField
                  multiline
                  fullWidth
                  onChange={this.handleChange}
                  value={this.state.content}
                />
                <Button
                  color={"primary"}
                  variant={"outlined"}
                  onClick={() => this.handleSubmit(this.state.content)}
                >
                  Done
                </Button>
              </CardContent>
            </Card>
          </Grid>
          }
          <Grid item xs={12}>
            <Card>
              <CardContent>
                {this.state.editing ?
                  <Typography color={"textSecondary"} gutterBottom>
                    Preview
                  </Typography>
                  :
                  <Typography color={"textSecondary"} gutterBottom>
                    By {this.props.user}:
                  </Typography>
                }
                <Divider/>
                {textToComponents(this.state.content)}
              </CardContent>
            </Card>
          </Grid>

          <LoginConsumer>
            {({user}) =>
              <Fragment>
                {(this.props.user === user && !this.state.editing) &&
                  <Grid item xs={12}>
                    <Button
                      variant={"outlined"}
                      onClick={() => this.setState({editing: true})}
                    >
                      Edit
                    </Button>
                  </Grid>
                }
              </Fragment>
            }
          </LoginConsumer>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(CodeSubmission);