import React from "react";
import {CircularProgress, withStyles} from "@material-ui/core";

const styles = () => ({
  cover: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(52, 52, 52, 0.5)",
  },
  progress: {
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: -32,
    marginLeft: -32,
  }
});

const PageLoading = (props) => {
  const {classes, hidden} = props;
  return (
    <div className={classes.cover} hidden={hidden}>
      <CircularProgress
        className={classes.progress}
        size={64}
      />
    </div>
  )
};

export default withStyles(styles)(PageLoading);