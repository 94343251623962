import React, {Component, createContext} from "react";
import {Typography} from "@material-ui/core";
import CodeBlock from "./components/code/codeBlock";
import {withCookies} from "react-cookie";

export const APIUrl = "https://zes9qkw5q3.execute-api.eu-west-1.amazonaws.com/Prod/";

let admin = false;
let user = null;

export const handleError = (location, err) => {
  console.log(location, err)
};

// take input text, and convert into components (code output)
export const textToComponents = (text) => {
  const parts = text.split('```');

  return parts.map((part, index) => {
    return (index % 2 === 0) ? (
      <Typography key={index} style={{whiteSpace: "pre-wrap"}}>
        {part}
      </Typography>
    ) : (
      <CodeBlock key={index} text={part}/>
    );
  });
};

const LoginContext = createContext({
  user: null,
  admin: false,
  setLoginUser: () => {},
  getLoginUser: () => {},
  delLoginUser: () => {},
});

class LoginComponent extends Component {
  setLoginUser = newUser => {
    const {cookies} = this.props;
    this.setState({
      user: newUser
    });
    return cookies.set('user', newUser, {path: "/"});
  };

  getLoginUser = () => {
    const {cookies} = this.props;

    const user = cookies.get('user');
    this.setState({
      user: user
    });

    if (user === "RichardK") {
      this.setState({
        admin: true
      })
    }
  };

  delLoginUser = () => {
    console.log("Delete login user");
    const {cookies} = this.props;

    this.setState({
      user: null,
      admin: false
    });
    cookies.remove('user', {path: "/"});
  };

  state = {
    user: 'bob',
    admin: false,
    setLoginUser: this.setLoginUser,
    getLoginUser: this.getLoginUser,
    delLoginUser: this.delLoginUser,
  };

  componentDidMount() {
    this.getLoginUser();
  }

  render() {
    return (
      <LoginContext.Provider value={this.state}>
        {this.props.children}
      </LoginContext.Provider>
    );
  }
}

export const LoginProvider = withCookies(LoginComponent);

export const LoginConsumer = LoginContext.Consumer;

// export const getUser = () => {
//   return user;
// };
//
// export const isAdmin = () => {
//   return admin;
// };
// export const setIsAdmin = (to) => {
//   admin = to;
// };