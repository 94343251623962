import React, {Component, Fragment} from "react";
import {APIUrl, handleError, textToComponents} from "../../global";
import {Divider, LinearProgress, Typography, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit"
import Fab from "@material-ui/core/Fab";
import CodeSubmission from "./codeSubmission";

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  }
});

class CodeProject extends Component {

  state = {
    project: {},
    loading: true,
    new: false,
    editing: false,
    userSubmission: "",
    submissions: [],
  };

  componentDidMount() {
    this.getProject();
    this.getUserSubmission();
  }

  getProject() {
    const {match} = this.props;

    fetch(APIUrl + match.params.projectid)
      .then(res => {
        switch (res.status) {
          case 403:
            this.setState({
              new: true,
            });
            break;
          default:
            return res.json()
        }
      })
      .then(data => {
        this.setState({project: data})
      })
      .catch(err => {
        handleError("Fetch project", err)
        this.setState({
          new: {},
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        });
        this.getAllSubmissions();
      })
  }

  saveProject() {
    const {match} = this.props;

    fetch(APIUrl + match.params.projectid, {
      method: "POST",
      body: JSON.stringify(this.state.project)
    })
      .then(res => {
        this.setState({
          editing: false
        })
      })
      .catch(err => {
        handleError("Save Project", err)
      })
      .finally(() => {
        this.getProject()
      })
  }

  getAllSubmissions = () => {
    const {match} = this.props;

    fetch(APIUrl + match.params.projectid + "/list")
      .then(res => res.json())
      .then(data => {
        this.setState({
          submissions: data
        })
      })
      .catch(err => {
        handleError("Get All Submissions", err)
      })
  };

  getUserSubmission = () => {
    const {match, user} = this.props;

    fetch(APIUrl + match.params.projectid + "/" + user)
      .then(res => res.json())
      .then(data => {
        this.setState({
          userSubmission: data
        })
      })
      .catch(err => {
        handleError("Get User Submission", err)
      })
  };

  saveUserSubmission = (data) => {
    const {match, user} = this.props;

    fetch(APIUrl + match.params.projectid + "/" + user, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .catch(err => {
        handleError("Save User Submission", err)
      })
      .finally(() => this.getUserSubmission())
  };

  render() {

    console.log("render:", this.props);
    const {classes, match, user, admin} = this.props;
    return (
      <Fragment>
        {this.state.editing &&
        <Fragment>
          <div>
            <TextField
              label={"Title"}
              fullWidth
              value={this.state.project.title || ""}
              onChange={e => {
                this.setState({
                  project: Object.assign(this.state.project, {
                    title: e.target.value
                  })
                })
              }}
            />
          </div>

          <div>
            <TextField
              multiline
              fullWidth
              label={"Description"}
              value={this.state.project.desc || ""}
              onChange={e => {
                this.setState({
                  project: Object.assign(this.state.project, {
                    desc: e.target.value
                  })
                })
              }}
            />
          </div>

          <div>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => this.saveProject()}
            >
              Save
            </Button>
          </div>

        </Fragment>
        }

        <Fragment>
          {this.state.loading &&
          <LinearProgress variant={"query"}/>
          }


          {this.state.project.title &&
          <Typography variant={"headline"}>{this.state.project.title}</Typography>
          }
          {this.state.project.desc &&
          textToComponents(this.state.project.desc)
          }

          {this.state.new &&
          <Button onClick={() => this.setState({
            editing: true,
            new: false,
          })}>Create Project</Button>}
        </Fragment>

        <Divider className={classes.divider}/>

        <Typography variant={"headline"}>
          Submissions
        </Typography>
        <Typography variant={"caption"}>
          {
            (() => {
              switch (this.state.submissions.length) {
                case 0:
                  return "There are no submissions, be the first!";
                case 1:
                  return "There is currently 1 submission";
                default:
                  return "There are currently " + this.state.submissions.length + " submissions";
              }
            })()
          }
        </Typography>

        {this.state.submissions.includes(user) ?
          this.state.submissions.map((submission, subIndex) => {
            return (
              <CodeSubmission
                key={subIndex}
                user={submission}
                path={match.params.projectid + "/" + submission}
                submit={this.saveUserSubmission}
              />
            )
          })
          :
          <Fragment>
            {(!!user) && <CodeSubmission
              user={user}
              path={match.params.projectid + "/" + user}
              submit={this.saveUserSubmission}
            />
            }
          </Fragment>
        }

        <Divider className={classes.divider}/>

        {admin &&
        <Fab
          className={classes.fab}
          color={"secondary"}
          onClick={() => this.setState({editing: true})}
        >
          <EditIcon/>
        </Fab>
        }

      </Fragment>
    );
  }
}

export default withStyles(styles)(CodeProject);