import {Link, Route} from "react-router-dom";
import {Fragment} from "react";
import {Button, Divider, Typography, withStyles} from "@material-ui/core";
import CodeBlock from "./code/codeBlock";
import CodeCore from "./code/codeCore";
import MigrationComponent from "./migrationComponents/migration";
import React from "react";

const styles = theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    maxWidth: 750,
    minHeight: "100vh",
    margin: "0 auto",
  },
  body: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  }
});

const MainContent = (props) => {
  const {classes} = props;
  return (
    <main className={classes.content}>
      <div className={classes.toolbar}/>

      <Route exact path={'/'} render={() => (
        <Fragment>
          <Typography variant={"headline"}>
            Welcome!
          </Typography>
          <Typography variant={"body1"} className={classes.body}>
            The idea here, is to explore ideas, and enjoy finding new ways to look at how we code.<br/>
            We'll be starting with a simple idea, and look at how we solve it.<br/>
            Following that, I'll be proposing some ideas, and taking suggestions for other ideas, and
            improvements in how this is organised.<br/>
          </Typography>

          <Divider variant={"middle"} className={classes.body}/>
          <Typography variant={"body2"} className={classes.body}>
            The site is currently very basic, and has no authentication.<br/>
            We will initially operate an honour system, and take it on faith that people will not impersonate
            each
            other
          </Typography>
          <Divider variant={"middle"} className={classes.body}/>

          <Typography>
            Sessions will be bi-weekly to start with, so that everyone has plenty of time.<br/>
            When writing a submission, you can use triple-backticks to outline code, as below
          </Typography>
          <CodeBlock text={`// Sample Code
func main() {
  // do my amazing code!
}`}
          />

          <Divider variant={"middle"} className={classes.body}/>
          <Typography>
            Current projects can be accessed from the code page, either the main menu, or this button:
            <Button
              component={Link}
              to={"/code"}
            >
              Current Projects
            </Button>
          </Typography>

          <Divider variant={"middle"} className={classes.body}/>
          <Typography>
            If you have any ideas for improvements to the site (style, features etc.) please mention them on
            Slack.
          </Typography>
        </Fragment>
      )}/>

      <Route path={'/code/'} component={CodeCore}/>

      <Route path={'/migrate'} component={MigrationComponent}/>
    </main>
  )
}

export default withStyles(styles)(MainContent);