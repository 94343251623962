import {Button, withStyles} from "@material-ui/core";
import React from "react";
import green from "@material-ui/core/colors/green";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


const LoadingButton = ({onClick, loading, success, text, classes}) => {
  return (
    <div className={classes.wrapper}>
      <Button
        color={"primary"}
        variant={"contained"}
        disabled={loading}
        className={(success) ? classes.buttonSuccess : null}
        onClick={onClick}
      >
        {text}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
    </div>
  )
};

export default withStyles(styles)(LoadingButton)