import React, {Component, Fragment} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CodeProject from "./project";
import {Link, Route, Switch} from "react-router-dom";
import {APIUrl, handleError, LoginConsumer} from "../../global";
import TextField from "@material-ui/core/es/TextField/TextField";
import PageLoading from "../pageLoading";

class CodeCore extends Component {

  state = {
    projects: [],
    codeText: "flange",
    addEditProject: null,
    pageLoading: false
  };

  getProjects() {
    this.setState({
      pageLoading: true
    });
    fetch(APIUrl + "projects")
      .then(res => res.json())
      .then(data => {
        this.setState({projects: data})
      })
      .catch(err => {
        handleError("Fetch projects", err)
      })
      .finally(() => {
        this.setState({
          pageLoading: false
        })
      })
  }

  setProjects() {
    fetch(APIUrl + "projects", {
      method: "POST",
      body: JSON.stringify(this.state.projects)
    })
      .then(data => {
        console.log("Project data", data);
      })
      .catch(err => {
        handleError("Set projects", err)
      })
      .finally(() => this.getProjects())
  };

  addProject(project) {
    let newProjects = this.state.projects;
    newProjects.push(project);

    this.setState({
      projects: newProjects
    });

    this.setProjects()
  }

  editProject = project => {
    console.log(project);
    this.setState({
      addEditProject: Object.assign({}, project)
    })
  };

  editSave = () => {
    // catch new projects
    if (!this.state.addEditProject.id) {
      const newProject = this.state.addEditProject;
      newProject.id = this.nameToId(this.state.addEditProject.name)
      this.addProject(newProject);
      this.setState({
        addEditProject: null
      });
      return
    }

    let newProjects = this.state.projects.map((p, i) => {
      if (p.id === this.state.addEditProject.id) {
        return this.state.addEditProject;
      } else {
        return p;
      }
    });
    console.log(newProjects);
    this.setState({
      addEditProject: null,
      projects: newProjects
    }, this.setProjects)
  };

  nameToId(name) {
    return (name.split(" ").map((namePart) => {
      return namePart.slice(0, 3).toLowerCase()
    }).reduce((acc, cur) => {
      return acc + "-" + cur;
    }));
  }

  componentDidMount() {
    this.getProjects();
    // this.setProjects()
  }

  render() {
    const {match} = this.props;

    return (
      <Fragment>
        <PageLoading hidden={!this.state.pageLoading}/>
        <Switch>
          <Route
            path={match.url + "/:projectid"}
            render={props =>
              <LoginConsumer>
                {({user, admin}) => <CodeProject user={user} admin={admin} {...props}/>}
              </LoginConsumer>
            }
          />


          <Fragment>
            <Typography variant={"headline"}>
              Project list:
            </Typography>
            <Typography variant={"caption"}>
              Below, you will find a list of the current projects
            </Typography>
            <List>
              {this.state.projects.map((project, index) => {
                return (
                  <ListItem
                    key={index}
                    button
                    component={Link} to={match.url + "/" + project.id}
                  >
                    <ListItemText>
                      {project.name}
                    </ListItemText>
                    <LoginConsumer>
                      {({admin}) =>
                        <ListItemSecondaryAction>
                          {project.tags && project.tags.map((tag, tagIndex) => {
                            return (
                              <Chip
                                key={tagIndex}
                                label={tag}
                              />
                            )
                          })}
                          {admin &&
                          <Button
                            variant={"outlined"}
                            onClick={() => this.editProject(project)}
                          >
                            Edit
                          </Button>
                          }
                        </ListItemSecondaryAction>
                      }
                    </LoginConsumer>
                  </ListItem>
                )
              })}
            </List>
            <LoginConsumer>
              {({admin}) =>
                <Fragment>
                  {admin &&
                  <Button
                    variant={"outlined"}
                    onClick={() => this.editProject({name: "", tags: []})}>
                    New Project
                  </Button>
                  }
                </Fragment>
              }
            </LoginConsumer>

            <Dialog open={(!!this.state.addEditProject)}>
              <DialogTitle>
                Add/Edit Project
              </DialogTitle>
              {this.state.addEditProject &&
              <DialogContent>
                <TextField
                  fullWidth
                  label={"Project ID"}
                  disabled
                  value={this.state.addEditProject.id || this.nameToId(this.state.addEditProject.name)}
                />
                <TextField
                  fullWidth
                  label={"Project Name"}
                  value={this.state.addEditProject.name}
                  onChange={e => this.setState({
                    addEditProject: Object.assign(this.state.addEditProject, {
                      name: e.target.value
                    })
                  })}
                />
                <TextField
                  fullWidth
                  label={"Tags"}
                  value={this.state.addEditProject.tags}
                  onChange={e => this.setState({
                    addEditProject: Object.assign(this.state.addEditProject, {
                      tags: e.target.value.split(",")
                    })
                  })}
                />
                {this.state.addEditProject.tags.map((tag, tagIndex) => {
                  return (
                    <Chip
                      key={tagIndex}
                      label={tag}
                    />
                  )
                })}
              </DialogContent>
              }
              <DialogActions>
                <Button onClick={() => this.setState({addEditProject: null})}>
                  Cancel
                </Button>
                <Button onClick={() => this.editSave()}>
                  Done
                </Button>
              </DialogActions>
            </Dialog>

          </Fragment>
        </Switch>
      </Fragment>
    )
  }
}

export default CodeCore