import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {List, ListItem, ListItemText} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FingerprintIcon from "@material-ui/icons/Fingerprint"
import SettingsIcon from "@material-ui/icons/Settings"
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet"
import Typography from "@material-ui/core/Typography";

const WaitingJobsAlert = ({open, onClose, waitingJobs, serialnumber}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Wating jobs - " + serialnumber}</DialogTitle>
      <DialogContent>
        {(waitingJobs.length > 0) ?
          <List dense>
            {waitingJobs.map((job, index) => {
              return (
                <ListItem key={index}>
                  <ListItemIcon>
                    {(job.type === "PPP") ? <SettingsEthernetIcon/> :
                      (job.type === "validate") ? <FingerprintIcon/> : <SettingsIcon/>}
                  </ListItemIcon>
                  <ListItemText
                    primary={job.type === "PPP" ? job.type : job.param + " - " + job.type}
                    secondary={job.value}
                  />
                </ListItem>
              )
            })}
          </List>
          :
          <Typography>No Waiting Jobs</Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default WaitingJobsAlert