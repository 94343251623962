import React, {Component} from "react";
import {
  Button,
  ExpansionPanel, ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  withStyles
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import red from '@material-ui/core/colors/red'
import orange from '@material-ui/core/colors/orange'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'
import LoadingButton from "../loadingButton";

const connectionTypes = {
  1: "ADSL",
  2: "VDSL",
  3: "EthWAN",
};

const styles = theme => ({
  successPanel: {
    backgroundColor: green[500]
  },
  warnPanel: {
    backgroundColor: orange[500]
  },
  failPanel: {
    backgroundColor: red[500]
  },
  unknownPanel: {
    backgroundColor: grey[500]
  },
});

const urlToClass = url => {
  switch (url) {
    case "http://verastar.broadbandsetup.uk/parked":
      return "successPanel";
    case "http://verastar.broadbandsetup.uk/validate":
    case "http://verastar.broadbandsetup.uk/bootstrap":
      return "warnPanel";
    case "http://acs.broadbandsetup.uk/parked":
      return "failPanel";
    default :
      return "unknownPanel";
  }
};

class RouterDetailComponent extends Component {
  state = {
    validateSuccess: false,
    validateLoading: false,
  };

  setAcsUrl = () => {
    this.setState({
      validateLoading: true,
      validateSuccess: false,
    });
    const apiURL = "https://mephala-config.juniperbridge.com/api/device/" + this.props.router.serialnumber + "/set";
    fetch(apiURL, {
      method: 'post',
      // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: new URLSearchParams({
        "jobname": "serverurl",
        "jobvalue": "http://verastar.broadbandsetup.uk/bootstrap"
      })
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        this.setState({
          validateSuccess: true
        })
      })
      .catch(err => {
        console.log("Error sending to validate")
      })
      .finally(() => {
        this.setState({
          validateLoading: false
        })
      });
    console.log("SET ACS URL - ", apiURL);
  };

  render() {
    const {router, expanded, handleChange, handleGetJobs, classes} = this.props;
    const {validateLoading, validateSuccess} = this.state;

    return (
      <ExpansionPanel onChange={handleChange} expanded={expanded}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} className={classes[urlToClass(router.serverurl)]}>
          <Typography>
            {router.serialnumber} - {router.serverurl}
          </Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Typography variant={"caption"}>
            Type: <br/>
            Serial: <br/>
            Manufacturer: <br/>
            Firmware: <br/>
            External IP: <br/>
            Connection type: <br/>
          </Typography>
          <Typography variant={"caption"}>
            {router.productclass}<br/>
            {router.serialnumber}<br/>
            {router.manufacturer}<br/>
            {router.devicefirmware}<br/>
            {router.externalipaddress}<br/>
            {connectionTypes[router.connectiontype]}<br/>
          </Typography>
        </ExpansionPanelDetails>

        <ExpansionPanelActions>
          <Button
            variant={"contained"}
            onClick={() => handleGetJobs(router.serialnumber)}
          >
            Get Waiting Jobs
          </Button>
          <LoadingButton
            loading={validateLoading}
            success={validateSuccess}
            onClick={this.setAcsUrl}
            text={"Send to Validate"}
          />
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(RouterDetailComponent);