import React from "react";
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {grey} from "@material-ui/core/colors";
import * as PropTypes from "prop-types";

const styles = theme => ({
  root: {
    padding: (theme.spacing.unit * 1.5) + 2,
    fontFamily: "Roboto Mono",
    fontSize: 14,
    width: 400,
    backgroundColor: grey[200],
    whiteSpace: "pre-wrap",
  }
});

const CodeBlock = (props) => {
  const {classes, text} = props;
  return (
    <Paper
      className={classes.root}
      elevation={0}
    >
      {text}
    </Paper>
  )
};

CodeBlock.propTypes = {
  text: PropTypes.string
};

export default withStyles(styles)(CodeBlock)